(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

/* ========================================================================
* Fast FLoor JavaScript main engine room
* ====================================================================== */
(function ($, window, document, undefined) {
  'use strict';

  var DOM = {
    $window: $(window),
    $body: $('body'),
    $burger: $('#burger'),
    $aside: $('#aside')
  };
  var A = [{
    from: '(.*)',
    to: '(.*)',
    out: function out(next) {
      gsap.set('#main', {
        autoAlpha: 1
      });

      if (DOM.$burger.hasClass('is-open')) {
        DOM.$burger.removeClass('is-open');
        DOM.$body.removeClass('is-freezed');
        gsap.fromTo('#aside', 0.4, {
          right: '0%'
        }, {
          right: '-100%',
          ease: 'power3.inOut',
          onComplete: function onComplete() {
            DOM.$body.removeClass('menu-is-open');
            U.resetMenuUI();
          }
        });
        gsap.to('#main', 0.4, {
          autoAlpha: 0,
          delay: 0.3,
          ease: 'power3.out',
          onComplete: next
        });
      } else {
        U.resetMenuUI();
        gsap.to('#main', {
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power3.out',
          onComplete: next
        });
      }
    },
    "in": function _in(next) {
      gsap.set(DOM.$window, {
        duration: 0,
        scrollTo: 0
      });
      gsap.set('#main', {
        autoAlpha: 0
      });
      gsap.to('#main', 0.8, {
        autoAlpha: 1,
        ease: 'power3',
        onComplete: next
      });
    }
  }];
  var R = new Swup({
    containers: ['#main', '#languages'],
    cache: true,
    plugins: [new SwupJsPlugin(A), new SwupBodyClassPlugin()]
  });
  var U = {
    loadCSS: function loadCSS(src) {
      var ref = window.document.getElementsByTagName("link")[0];
      var style = window.document.createElement("link");
      style.rel = 'stylesheet';
      style.href = src;
      style.type = 'text/css';
      ref.parentNode.insertBefore(style, ref);
      return style;
    },
    loadJS: function loadJS(src, cb, ordered) {
      var tmp;
      var ref = window.document.getElementsByTagName("script")[0];
      var script = window.document.createElement("script");

      if (typeof cb === 'boolean') {
        tmp = ordered;
        ordered = cb;
        cb = tmp;
      }

      script.src = src;
      script.async = !ordered;
      ref.parentNode.insertBefore(script, ref);

      if (cb && typeof cb === "function") {
        script.onload = cb;
      }

      return script;
    },
    getUrlParameter: function getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    resetMenuUI: function resetMenuUI() {
      DOM.$body.find('#aside .current-menu').removeClass('current-menu');
      DOM.$body.find('#aside .active-sub-item').removeClass('active-sub-item');
      DOM.$body.find('#aside .current-sub-item').removeClass('current-sub-item');
      DOM.$body.find('#aside .current-item').removeClass('current-item');
      gsap.set('.menu-list', {
        x: -10,
        autoAlpha: 0
      });
    }
  };
  var X = {
    'common': function common(args) {
      DOM.$burger.off().on('click', function () {
        gsap.to(window, 0.4, {
          scrollTo: 0,
          ease: 'circ.inOut'
        });

        if (!DOM.$body.hasClass('menu-is-open') && !DOM.$burger.hasClass('is-open')) {
          DOM.$body.addClass('is-freezed is-overlayed');
          DOM.$burger.addClass('is-open');
          gsap.fromTo('#aside', 0.6, {
            right: '-100%',
            transformOrigin: '100% 50%'
          }, {
            right: '0%',
            autoAlpha: 1,
            y: 0,
            ease: 'power3.inOut',
            onComplete: function onComplete() {
              DOM.$body.addClass('menu-is-open');
            }
          });
        }

        if (DOM.$body.hasClass('menu-is-open') && DOM.$burger.hasClass('is-open')) {
          DOM.$burger.removeClass('is-open');
          DOM.$body.removeClass('is-freezed is-overlayed');
          gsap.fromTo('#aside', 0.6, {
            right: '0%'
          }, {
            right: '-100%',
            ease: 'power3.inOut',
            onComplete: function onComplete() {
              DOM.$body.removeClass('menu-is-open');
              U.resetMenuUI();
            }
          });
        }
      });
      $('#next').off().on('click', function () {
        gsap.to(window, 0.6, {
          scrollTo: '#about',
          ease: 'circ.inOut'
        });
      });
      $('#aside').off().on('click', '.button-link', function () {
        var $that = $(this),
            target = $that.data('target'),
            $currentItem = $('.current-sub-item'),
            $currentSubMenu = $('.current-menu'),
            $subMenu = $('#' + target);

        if ($currentSubMenu.length) {
          $currentItem.removeClass('current-sub-item');
          $that.closest('.menu').removeClass('active-sub-item');
          gsap.to($currentSubMenu, 0.2, {
            x: -10,
            autoAlpha: 0,
            onComplete: function onComplete() {
              $currentSubMenu.removeClass('current-menu');
            }
          });
        }

        if (!$currentSubMenu.is($subMenu)) {
          $that.addClass('current-sub-item');
          $that.closest('.menu').addClass('active-sub-item');
          gsap.fromTo($subMenu, 0.4, {
            x: 40,
            autoAlpha: 0
          }, {
            x: 0,
            autoAlpha: 1,
            delay: $currentSubMenu.length ? 0.2 : 0,
            onComplete: function onComplete() {
              $subMenu.addClass('current-menu');
            }
          });
        }
      });
      var areasSS = new Swiper('#aside .swiper-container', {
        speed: 400,
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerGroup: 2,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          768: {
            spaceBetween: 40,
            slidesPerView: 3,
            slidesPerGroup: 3
          }
        }
      });
    },
    'home': function home(args) {
      var homeSS = new Swiper('#hero .swiper-container', {
        speed: 500,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        }
      });
    },
    'area': function area(args) {},
    'product': function product(args) {
      $('#presentation .media a').simpleLightbox({
        showCaptions: false
      });
    },
    'contacts': function contacts(args) {
      DOM.$aside.find('a.menu-item[href="' + window.location.href + '"]').addClass('current-item');

      if (!device.mobile()) {
        U.loadCSS('https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css');
        U.loadJS('https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.js', function () {
          mapboxgl.accessToken = 'pk.eyJ1IjoiaG9ydGVsYW1hZ2VudGEiLCJhIjoiY2szaXR5Ynh0MGE1bDNwcms2aTB0cWlrYyJ9.rAouJ4esum58L-XPVPVapA';
          fetch(window.location.href + '.json', {
            method: 'GET',
            headers: {
              'X-Requested-With': 'fetch'
            }
          }).then(function (response) {
            return response.json();
          }).then(function (locations) {
            var map = new mapboxgl.Map({
              container: 'map-inner',
              style: 'mapbox://styles/mapbox/light-v10',
              center: locations.locations.location_0.center,
              zoom: 6
            });
            map.scrollZoom.disable();
            map.on("load", function () {
              map.loadImage(locations.marker, function (error, image) {
                if (error) throw error;
                map.addImage("ff-marker", image);
                map.addLayer({
                  id: "markers",
                  interactive: true,
                  type: "symbol",
                  source: {
                    type: "geojson",
                    data: {
                      type: 'FeatureCollection',
                      features: locations.geojson
                    }
                  },
                  layout: {
                    "icon-image": "ff-marker"
                  }
                });
              });
              setTimeout(function () {
                map.flyTo({
                  curve: 2,
                  speed: 0.5,
                  center: locations.locations.location_0.center,
                  zoom: 12,
                  essential: true
                });
                $('.location button[data-target="' + locations.locations.location_0.id + '"]').addClass('active-location');
              }, 1000);

              if (!device.mobile()) {
                $('.location').off().on('click', 'button', function () {
                  var $that = $(this),
                      $activeLocation = $('.active-location');

                  if (!$that.is($activeLocation)) {
                    var target = $that.data('target');
                    $activeLocation.removeClass('active-location');
                    $that.addClass('active-location');
                    map.flyTo({
                      curve: 2,
                      speed: 0.5,
                      center: locations.locations[target].center,
                      zoom: 12,
                      essential: true
                    });
                  }
                });
              }
            });
          });
        });
      }

      var $form = $(".contact-form"),
          fields = {};
      $form.find('[required]').each(function (i, field) {
        fields[field.name] = $(field);
      });
      $form.submit(function (e) {
        e.preventDefault();
        var $that = $(this),
            $formMiddle = $form.closest('.inner').find('.form-middle'),
            $formFooter = $form.closest('.inner').find('.form-footer'),
            url = $that.attr('action');
        $that.find('.error').removeClass('error');
        $that.addClass('disabled');
        fetch(url, {
          method: 'POST',
          body: $that.serialize()
        }).then(function (r) {
          return r.json().then(function (data) {
            return {
              status: r.status,
              body: data
            };
          });
        }).then(function (result) {
          if (400 === result.status) {
            $that.removeClass('disabled');
            var errors = [];

            for (var key in result.body) {
              if (!(key in fields)) continue;

              if (key in fields) {
                fields[key].closest('.field').addClass('error');
                fields[key].after('<span class="not-valid-tip">' + result.body[key] + '</div>');
              }
            }
          } else if (200 === result.status) {
            gsap.to($formMiddle, 0.6, {
              autoAlpha: 0,
              y: -15,
              delay: 0.2
            });
            gsap.fromTo($formFooter, 0.6, {
              autoAlpha: 0,
              y: 15,
              delay: 0.6
            }, {
              autoAlpha: 1,
              y: 0,
              onComplete: function onComplete() {
                $form.find('[required]').each(function (i, field) {
                  if ($(field).is(':checkbox')) {
                    $(field).prop("checked", false);
                  } else {
                    $(field).val("");
                  }
                });
                $that.removeClass('disabled');
              }
            });
            gsap.to($formFooter, 0.6, {
              delay: 5,
              autoAlpha: 0,
              y: -15
            });
            gsap.fromTo($formMiddle, 0.6, {
              autoAlpha: 0,
              y: 15
            }, {
              autoAlpha: 1,
              y: 0,
              delay: 5.4
            });
          }
        });
      });
    },
    'default': function _default(args) {}
  };
  var _ = {
    // fire: function(func, funcname, args) {
    fire: function fire(func, args) {
      var fire; // funcname = (funcname === undefined) ? 'init' : funcname;

      fire = func !== ''; // fire = fire && X[func];
      // fire = fire && typeof X[func][funcname] === 'function';

      fire = fire && typeof X[func] === 'function';

      if (fire) {
        // X[func][funcname](args);
        X[func](args);
      }
    },
    load: function load() {
      // Fire common init JS
      _.fire('common'); // Fire page-specific init JS, and then finalize JS


      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        _.fire(classnm); // _.fire(classnm, 'finalize');

      }); // Fire common finalize JS
      // _.fire('common', 'finalize');
    }
  };

  _.load();

  R.on('contentReplaced', function () {
    _.load();

    if (typeof window.gtag === 'function') {
      var title = document.title;
      var url = window.location.pathname + window.location.search;
      window.gtag('config', 'UA-XXXXXXX-1', {
        page_title: title,
        page_path: url
      });
    }
  });
})(jQuery, window, document);

},{}]},{},[1]);
